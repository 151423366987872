<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {

    data() {
        return {
            variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        // headerBgVariant: 'dark',
        // headerTextVariant: 'light',
        // bodyBgVariant: 'light',
        // bodyTextVariant: 'dark',
        footerBgVariant: 'white',
        footerTextVariant: 'dark',
        }
    },

    components: {
                    VueGoogleAutocomplete,
                },
    methods : {
                /**
                 * When the location found
                 * @param {Object} addressData Data of the found location
                 * @param {Object} placeResultData PlaceResult object
                 * @param {String} id Input container ID
                 */
                getAddressData: function (addressData, placeResultData) {
                    this.address = addressData;
                    if(placeResultData){
                        this.addressInput = placeResultData.formatted_address;
                        this.latitude = placeResultData.geometry.location.lat();
                        this.longitude = placeResultData.geometry.location.lng();  
                    }
                    //   console.log(this.addressInput);
                    //   console.log(this.latitude);
                    //   console.log(this.longitude);
                },
                findResLocation(){
                    if(this.addressInput == '' || this.addressInput == undefined)
                    {
                        alert("Please type your address");
                    }
                    else
                    {
                    
                        var addressData = {
                                            "address":this.addressInput,
                                            "latitude":this.latitude,
                                            "longitude":this.longitude,
                                        }
                                        localStorage.setItem('placesAddress',JSON.stringify(addressData)); 
                        // location.reload();
					    window.location.href = '/';
                    }
                },
    },
}
</script>
<template>
    <div  class="container-fuild m-0 p-0">
<!-- Modal item order -->
  <b-modal id="modal-places"  modal-class="addDeliveryModal" :footer-bg-variant="footerBgVariant" hide-header  size="md" centered>
		<div class="container deliveryAddContainer">
		<div class="clearfix addDeliveryHeader">
            <h4>Change Address</h4>
        </div>
        <!-- / clearfix -->

        <div class="deliveryLocat">
            <vue-google-autocomplete
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Please type your address"
            v-on:placechanged="getAddressData"
            country="in"
            types=""
        >
        </vue-google-autocomplete>
        </div>
        <!-- / deliveryLocat -->

		</div>
		<!-- /Container -->
		<template #modal-footer>
        <div class="w-100">
			  
				<b-button
					size="sm"
					class="float-right btnSave btn btn-warning"
					@click="findResLocation"
				>
					Change Address
				</b-button>
                <!-- / footer Btn end -->
        </div>
      </template>
  </b-modal> 
    <!-- /Modal item order -->
    </div>
</template>
<style scoped>
    .closeModalDiv{
        float: right;
        padding-top: 6px;
        padding-right: 9px;
        cursor: pointer;
    }
    .custom-search-input button[type='submit']{
        padding: 0!important;
    }
    .custom-search-input{
        /* margin: 0px 15px 0 15px; */
        margin: 50px 15px;
    }
</style>
<style>
.pac-container {
    background-color: #FFF;
    /* z-index: 20; */
    z-index: 10000!important;
    position: fixed;
    display: inline-block;
    float: left;
}
#modal-places{
    z-index: 20;   
}
.modal-backdrop{
    z-index: 10;        
}
#modal-places___BV_modal_content_{
        height: 390px!important;
    }
</style>