<script>
import loginRegModal from "../../views/pages/popUpModals/loginReg";
import termsAndCondition from "../../views/pages/popUpModals/termsAndCondition";
import cusinePopup from "../../views/pages/popUpModals/cusinePopup";
import placesPopup from "../../views/pages/popUpModals/placesPopup";
import feedBackPopup from "../../views/pages/popUpModals/userFeedbackPopup";
// import cafePage from "../../views/pages/meals/cafeterias";
// import searchBoxPopup from "../../views/pages/popUpModals/searchBoxPopup";
// import deliveryAddressPopup from "../../views/pages/popUpModals/deliveryAddressPopup";
import jwt_decode from "jwt-decode";
import Vue from 'vue'
import simplebar from "simplebar-vue";

import Drawer from "vue-simple-drawer"

export default {
	// props:[
	// 	// 'cartDetailsHead',
	// 	// 'cartItemsDetailsHead'
	// 	// 'cafeRestBranchID'
	// ],
	// 	props:{
	// 		cartDetailsHead:Array,
	// 		cartItemsDetailsHead:Array
	// },
	// localStorage.clear();
	props:['isRestdetails'],
	data() {
		return {
			userDataForFeedback:0,
			feedBackData:[],
			showBackBtn: 0,
			localStorageUserData: '',
			userName: '',
			isUserFetched: 0,
			canUserLogout: 0,
			isCorporateUser: '',
			getAllMealTypes:[],
			cartDetailsHead:[],
			cartItemsDetailsHead:[],
			userID: '',
			corporateLogo:'',
			addressInputForHead:undefined,
			isCafePage:0,
			openMenuDrawer: false,
			//    cafeteriasForCorp:[],
			//   cafeRestBranchID:[],
		}
	},
    mounted()
	{
		// if (this.$userData !== null) {
		// 	this.localStorageUserData = this.$userData;
		// 	this.userName = this.$userData.userName;
		// 	this.userID = this.$userData.userID;
		// 	this.isCorporateUser = this.$userData.isCorporateUser;
		// 	this.corporateBranchID = this.$userData.corporateBranchID;
		// 	this.corporateLogo = this.$userData.corporateLogo;

		// 	// if((this.corporateBranchID == 0 || this.corporateBranchID == '') && this.isCorporateUser !== '' && this.isCorporateUser == 1)
		// 	// {
		// 	// 	this.$bvModal.show("modal-corporateBranch");
		// 	// 	this.geCorporateBranchByIDNew = loginRegModal.methods.geCorporateBranchByID;
		// 	// 	this.geCorporateBranchByIDNew();
		// 	// }

		// 	// if(this.isCorporateUser !== '' && this.isCorporateUser == 1){ // if corporate user get mealType
		// 	// this.getMealTypeByID();
		
		// 	// }
		// 	this.getMealTypeByID();
		// 	this.getCartDetails();
		// }
		
		// || this.$route.params.type == 'food_court'
		this.getUserData();

		if (localStorage.getItem("uTid") !== null) {
			this.getUserProfile();
		}

		if(this.$route.name && (this.$route.name == 'cafeterias' || this.$route.name == 'profile'))
		{
			this.isCafePage = 1;
		}
		if(this.$route.name && this.$route.name == 'restaurantCollections' || this.$route.name == 'restaurantDetailByShort' || this.$route.name == 'restaurantDetails' || this.$route.name == 'orderDetails')
		{
			this.showBackBtn = 1;
		}
		this.getMealTypeByID();
		this.getCartDetails();

		if (JSON.parse(localStorage.getItem('placesAddress')) !== null) {
			this.addressInputForHead = JSON.parse(localStorage.getItem('placesAddress')).address;
			this.latitude = JSON.parse(localStorage.getItem('placesAddress')).latitude;
			this.longitude = JSON.parse(localStorage.getItem('placesAddress')).longitude;
		}
		// this.getAllCafeterias = cafePage.methods.getAllCafeterias;
		// this.getAllCafeterias();
		this.autoRefreshToken();
	},
	methods: {
		getUserProfile() {
			this.axios.get("/getUserProfile").then((res) => {

        		let status = res.status;

				if (status == 200 && res.data.status == 200) {
					localStorage.setItem("corporateCode", res.data.userData.corporateCode);
					localStorage.setItem("isCorporateUser", res.data.userData.isCorporateUser);
					this.userID = res.data.userData.userID;
					this.userMobileNo = res.data.userData.mobileNo;
				} else {
					console.log(res.data.message);
				}
			});
    	},

		autoRefreshToken()
		{
			if (localStorage.getItem('uTid') && localStorage.getItem('uTid') != null) 
			{
				var getExp = jwt_decode(localStorage.getItem('uTid'));
				var currentDate  = new Date();
				var tokenExpire = new Date(getExp.exp * 1000);
				var difference = tokenExpire.getTime() - currentDate.getTime(); // This will give difference in milliseconds;
				var resultInMinutes = Math.round(difference / 60000);
				if(resultInMinutes <= 15 && resultInMinutes >= 0)
				{
					this.refreshTokens();
				}
				else if (resultInMinutes < 0) {
					this.refreshTokens();
					// localStorage.clear();
					// Vue.prototype.$userData = '';
					// Vue.prototype.$placesAddress = '';
					// // this.$router.push('/');
					// window.location.href = '/';
				}
			}
		},

		refreshTokens()
		{
			// alert();
			this.axios.get('/autoRefreshToken')
			.then((response)=>{
				// console.log(response);
				let status = response.status;
				if(status == 200 && response.data.status == 200 && response.data.refreshedToken && response.data.refreshedToken != '')
				{
					console.log(response.data.refreshedToken);
					var userData = {
						"utid":response.data.refreshedToken,
					}
					localStorage.setItem('uTid',JSON.stringify(userData));
					location.reload();
				} // if token is expired so it can be refreshed
			}).catch((error) => {
				// console.log(error);
			});
		},

		backHistory()
		{
			this.$router.go(-1);
		},
		toggleMenuDrawer() {
			this.openMenuDrawer = !this.openMenuDrawer
		},

		openLoginModal()
		{
			this.openMenuDrawer = !this.openMenuDrawer;
			this.$bvModal.show("modal-login");
		},

		changeCafe()
		{
			this.ChangeCafeMenuByID = cafePage.methods.changeCafe;
			this.ChangeCafeMenuByID();  
		},

		getUserData()
    	{
        	this.axios.get('/getUserDeatils')
			.then((response)=>{
				// console.log(response);
				let status = response.status;
				//  console.log(JSON.parse(localStorage.getItem('uTid')));
				// if(response.data.token && response.data.token != '')
				// {
				// 	var userData = {
				//             "utid":response.data.token,
				//         }
				//         localStorage.setItem('uTid',JSON.stringify(userData));
				// } // if token is expired so it can be refreshed
					
				if(status == 200 && response.data.status == 200)
				{
					Vue.prototype.$isExtMob = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;
					this.isUserFetched = 1;
					this.canUserLogout = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;
					// if(response.data.userData.isFromExtMob && response.data.userData.isFromExtMob == 1)
					// {
					// 	var list_menu_div = document.getElementById("list_menu_div");
					// 	list_menu_div.classList.add("mobListExt");
					// 	var mobile_basket_div = document.getElementById("mobile_basket_div");
					// 	mobile_basket_div.classList.add("mobBasketExt");
					// }

					Vue.prototype.$isExtMob = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;	
					localStorage.setItem('isExtMob',JSON.stringify(this.$isExtMob));

					if (response.data.userData.placesAddress && this.$placesAddress == null) {	
						localStorage.setItem('placesAddress',JSON.stringify(response.data.userData.placesAddress));	
						Vue.prototype.$placesAddress = response.data.userData.placesAddress ? response.data.userData.placesAddress : [];	
						this.addressInput = this.$placesAddress.address;	
						this.latitude = this.$placesAddress.latitude;	
						this.longitude = this.$placesAddress.longitude;	
						this.getMealTypeByID();	
					}

					if (response.data.userData.userName) {
						localStorage.setItem('userID', response.data.userData.userID);
						localStorage.setItem('userName', response.data.userData.userName);

						this.userName = response.data.userData.userName;
						this.corporateLogo = response.data.userData.corporateLogo;
						this.userDataForFeedback = response.data.userData.userDataForFeedback;
						Vue.prototype.$isUserLoggedIn  = 1; 
						Vue.prototype.$corporateLogo  = response.data.userData.corporateLogo; 
					}
					else
					{
						this.userName = '';
						Vue.prototype.$isUserLoggedIn  = ''; 
						Vue.prototype.$corporateLogo  = '';
					}
					if(response.data.userData.isTncAgreed == 0)
					{
						this.$bvModal.show("modal-termsCondition");
					}
					if(response.data.userData.isOrderRated == 0  && response.data.userData.orderId > 0) // not rated
					{
						var orderId = response.data.userData.orderId;
						this.getFeedbackDataForUser(orderId);
					}
				}
				// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
				console.log(error.response.data.message);
			});
    	},

		getFeedbackDataForUser(orderId)
		{
			let getFeedBackData = JSON.stringify({ 
				orderID: orderId,
			});

			this.axios.post('/getFdbackQuestionUser', getFeedBackData)
			.then((response)=>{
				// console.log(response.data.cuisineDetails);
				let status = response.status;
				if(status == 200 && response.data.status == 200)
				{
					this.feedBackData = response.data;
					this.feedBackData.orderDetails.userDataForFeedback = this.userDataForFeedback;
					this.$bvModal.show("modal-userFeedback");
				}
			});
		},

		userRedirection(routerName)
		{
			if(this.userName != '' || this.isUserFetched != 0)
			{
				if (routerName != '/checkOut') {
					window.location.href = routerName;
                    return;
                }
				if (this.cartItemsDetailsHead.length > 0 && routerName == '/checkOut') {
					this.openMenuDrawer = false;
					// this.$router.push(routerName);	
					window.location.href = routerName;
				}
				else
				{
					alert('No items in cart');
				}
			}
			else
			{
                this.openMenuDrawer = !this.openMenuDrawer;
				this.$bvModal.show("modal-login");
			}
		},


		logout()
		{
			this.$confirm("Are you sure you want to logout?","Logout","")
			.then(() => {
				localStorage.clear();
				Vue.prototype.$userData = '';
				Vue.prototype.$placesAddress = '';
				// this.$router.push('/');
				window.location.href = '/';
			});
		},

		// getCheckOutAccess()
		// {
		// 	if(this.$userData !== null && this.$userData.mobileNo != null && this.$userData.mobileNo != '')
		// 	{
		// 		this.$router.push('/checkOut');
		// 	}
		// 	else
		// 	{
		// 		this.$bvModal.show("modal-mobile");
		// 	}
		// },

		getMealTypeByID()
		{
			// let corpBranchID = JSON.parse(localStorage.getItem('userData')).corporateBranchID;
			// if (JSON.parse(localStorage.getItem('userData')) !== null) {
			// 	var userID = JSON.parse(localStorage.getItem('userData')).userID;
			// }else{
			// 	var userID = "";
			// }
			// let jsonMealTypeData = JSON.stringify({ userID: userID,
            //                             });
			// this.axios.post('/getMealTypePageAccess', jsonMealTypeData)
			var isExtMob = 0;
            var address = "";
			var latitude ="";
            var longitude = "";
            if((this.$isExtMob != null && this.$isExtMob == 1) && (this.$placesAddress != null && this.$placesAddress.address))
            {
                var isExtMob = this.$isExtMob;
                var address = this.$placesAddress.address;
				var latitude = this.$placesAddress.latitude;
                var longitude = this.$placesAddress.longitude;
            }
			this.axios.get('/getMealTypePageAccess',{
				params: {
					isFromMobExt: isExtMob,
					address: address,
					latitude: latitude,
					longitude:longitude
				}
			})
			// var isExtMob = 0;
            // var address = "";
            // var latitude ="";
            // var longitude = "";
            // if((this.$isExtMob != null && this.$isExtMob == 1) && (this.$placesAddress != null && this.$placesAddress.address != ""))
            // {
            //     var isExtMob = this.$isExtMob;
            //     var address = this.$placesAddress.address;
            //     var latitude = this.$placesAddress.latitude;
            //     var longitude = this.$placesAddress.longitude;
            // }
            // let getMealPageFormData = JSON.stringify({ isFromMobExt: isExtMob,
            //                                             address: address,
			// 											latitude: latitude,
			// 											longitude:longitude
            //                                             });

            // this.axios.post('/getMealTypePageAccess', getMealPageFormData)
			.then((result)=>{
				// console.log(result.data.mealTypeTabs);
				if(result.data.mealTypeTabs.length !== 0)
				{
					localStorage.setItem('mealTypeTabsData',JSON.stringify(result.data));
					// console.log(localStorage.getItem('mealTypeTabsData'));
				}
				else
				{
					localStorage.removeItem('mealTypeTabsData');
				}
			});
		},

		getCartDetails()
		{
			// let getCartDetailsData = JSON.stringify({ userID: this.userID,
			// 										});
			// Vue.axios.post('/getCartDetails', getCartDetailsData)
			Vue.axios.get('/getCartDetails')
			.then((response)=>{
				// console.log(response);
				let status = response.status;
				if(status == 200 && response.data.status == 200)
				{
					if(response.data.cartDetails !== '' && response.data.cartItems !== '')
					{
						this.cartDetailsHead = response.data.cartDetails;
						this.cartItemsDetailsHead = response.data.cartItems;
					}
					else
					{
						this.cartDetailsHead = [];
						this.cartItemsDetailsHead = [];
					}
				}
				// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
				console.log(error.response.data.message);
			});
		},
	},
	components: {
    loginRegModal,
	termsAndCondition,
	cusinePopup,
	placesPopup,
	feedBackPopup,
	simplebar,
	// searchBoxPopup,
	Drawer,
	// deliveryAddressPopup,
  },
}
</script>
<template>
    <header class="header_in clearfix">
	    <div class="container">
			<div class="row">
				<!-- Mobile Menu -->
				<div class="col-2 onlyMobile align-center">
					<!-- <a href="#0" class="open_close"> -->
						<i v-if="showBackBtn == 1" @click="backHistory" style="font-size:22px;" class="uil uil-arrow-left"></i>
							<i v-else @click="toggleMenuDrawer" class="icon_menu"></i>
							<Drawer @close="toggleMenuDrawer" align="left" :maskClosable="true" :closeable="false">
                                             <div v-if="openMenuDrawer">
												 <section class="head_section">
													<div  v-if="isUserFetched != 0" class="row">
													 <a href="/profile/my-profile">
															<div class="align-left">
																<!-- <figure> -->
																	<img class="userPlaceImage" src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="50" width="50" alt="">
																	<!-- </figure> -->
															</div>
															<div class="align-left">
																<!-- <p class="userName userNameTextLen one-line-text" style="margin:8px 0 0 0;">{{userName}}</p> -->
																<a href="/profile/my-profile" class="userName" style="margin:8px 0 0 0; color:#212121; font-size:14px;">{{userName}}</a>
															</div>
														</a>
													</div>
													<div v-else class="row">
														<div class="align-left">
															<p class="loginReg" @click="openLoginModal">Login / Register</p>
														</div>
													</div>
												 </section>
												 <hr style="margin:0px;">
												 <!-- headMobileMenu -->

												 <section class="navigation_section">
													<ul class="dropdown-ul navigation_ul align-left">
														<a href="/"><li><img src="../templateAssests/img/ckgallery/CloudKitch_logo_solo.png" style="object-fit:contain;margin-right:10px;" width="25" height="25" alt="">Home</li></a>
														<a @click="userRedirection('/checkOut')"><li><img src="../templateAssests/img/ckgallery/Cart.png" width="25" height="25" style="object-fit:contain;margin-right:10px;" alt="">Cart</li></a>
														<a @click="userRedirection('/profile/my-orders')"><li><i class="icon_bag_alt" style="font-size:20px;margin-right:10px;"></i>My Orders</li></a>
														<!-- <a @click="userRedirection('/profile/my-subscriptions')"><li><i class="mdi mdi-calendar-clock" style="font-size:20px;margin-right:10px;"></i>My Subscriptions</li></a> -->
														<a @click="userRedirection('/profile/my-vouchers')"><li><i class="mdi mdi-ticket-percent-outline" style="font-size:20px;margin-right:10px;"></i>Pre-Buy Vouchers</li></a>
														<a @click="userRedirection('/profile/my-address')"><li><i class="far fa-address-book" style="font-size:20px;margin-right:10px;"></i>My Address</li></a>
														<a @click="userRedirection('/profile/my-review')"><li><i class="far fa-star" style="font-size: 20px; margin-right: 10px"></i>My Reviews</li></a>
														<a @click="userRedirection('/profile/wallet')"><li><i class="mdi mdi-wallet-outline" style="font-size:20px;margin-right:10px;"></i>Wallet</li></a>
														<a @click="userRedirection('/profile/my-profile')"><li><i class="mdi mdi-account-box-outline" style="font-size:20px;margin-right:10px;"></i>Profile</li></a>
													</ul> 
												 </section>
												 <hr class="hrBorder">
												 <!-- navigation_section -->

												 <section class="help_section">
													 <h3 class="helpHead align-left">Help & Support</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<a href="/policies/terms-and-condition"><li><i class="mdi mdi-file-document-multiple-outline" style="font-size:20px;margin-right:10px;"></i>Terms & Conditions</li></a>
														<a href="/policies/grievance-policy"><li><i class="mdi mdi-file-document-outline" style="font-size:20px;margin-right:10px;"></i>Grievance Policy</li></a>
														<a href="/policies/cookie-policy"><li><i class="mdi mdi-cookie-outline" style="font-size:20px;margin-right:10px;"></i>Cookie Policy</li></a>
														<a href="/policies/cancellation-refund"><li><i class="mdi mdi-cash-refund" style="font-size:20px;margin-right:10px;"></i>Cancellation & Refund</li></a>
														<a href="#"><li><i class="icon_mail_alt" style="font-size:20px;margin-right:10px;"></i>Contact Us</li></a>
													</ul> 
												 </section>
												 <hr class="hrBorder">
												 <!-- help_section -->

												 <section class="other_section" v-if="isUserFetched != '' && canUserLogout == 0">
													 <h3 class="helpHead align-left">Other</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<a @click="logout"><li><i class="uil uil-sign-out-alt" style="font-size:20px;margin-right:10px;"></i>Logout</li></a>
													</ul> 
												 </section>
												 <!-- <hr class="hrBorder"> -->
												 <!-- navigation_section -->
                                                 
                                             </div>
                                        </Drawer>
						<!-- </a> -->
				</div>
				<div class="col-6 onlyMobile align-center addressHead"  v-b-modal.modal-places>
					<p class="one-line-text" v-if="this.addressInputForHead != '' && this.addressInputForHead != undefined && isCafePage == 0">{{addressInputForHead}}</p> <i v-if="isCafePage == 0" class="arrow_carrot-down mobileArrowCarrot"></i>
					<div id="logo" style="width:fit-content;">
						<a href="/"  v-if="corporateLogo != '' && isCafePage == 1">
							<img :src="corporateLogo" style="object-fit:contain;" width="30" height="30" alt="">
						</a>
					</div>
				</div>
				<div v-if="isRestdetails==1" class="col-2 onlyMobile align-center serachImgDiv" v-b-modal.modal-searchBox>
					<img class="serachImg" src="../templateAssests/img/ckgallery/search.png" width="30" height="30" alt="">
				</div>
				<div class="col-2 onlyMobile align-center">
					<a href="/">
						<img src="../templateAssests/img/ckgallery/CloudKitch_logo_solo.png" style="object-fit:contain;" width="30" height="30" alt="">
					</a>
				</div>

				<!-- / mobile Menu -->

				<!-- Desktop Menu -->
				<div class="col-md-4 col-lg-3 col-xl-4 noMobile" style="top:3px;">
					<div id="logo" v-if="corporateLogo != ''">
						<a href="/">
							<img :src="corporateLogo" style="object-fit:contain;" width="148" height="50" alt="">
						</a>
					</div>
					<div class="d-none d-md-block headerAddressDiv" v-if="isCafePage == 0" v-b-modal.modal-places>
                            <h1 class="headerAddress one-line-text" v-if="this.addressInputForHead != '' && this.addressInputForHead != undefined">{{addressInputForHead}}</h1>
                            <!-- <h1 class="one-line-text" v-else>145 restaurants in Convent Street 2983</h1> -->
                            <a class="customAnch" style="font-size:12px;" v-b-modal.modal-places>Change address</a>
                    </div>
					 <!-- <multiselect v-if="isCafePage == 1" @input="changeCafe" v-model="cafeRestBranchID" :options="cafeteriasForCorp" track-by="restBranchID" label="restBranchName"></multiselect>
						<a href="#0" class="customAnch changeCafe">Change Cafeteria</a> -->
				</div>
				
				<div class="col-md-4 col-lg-3 col-xl-4 noMobile mainLogoDiv align-center">
					<div id="mainLogo logo">
						<a href="/">
							<img class="mainLogoImg" src="../templateAssests/img/cklogo.png" width="121" height="55" alt="">
						</a>
					</div>
				</div>
					<div class="col-md-4 col-lg-6 col-xl-4 align-right noMobile" style="margin-top:10px;">
						<a v-if="isCafePage==1||isRestdetails==1" class="serachImgDiv" v-b-modal.modal-searchBox>
							<img class="serachImg" src="../templateAssests/img/ckgallery/search.png" width="30" height="30" alt="">
						</a>
						<!-- <img src="../templateAssests/img/ckgallery/Cart.png" width="30" height="30" style="margin-top:4px;margin-right:34px;" alt=""> -->
						<!-- <b-dropdown
							variant="white"
							class="dropdown d-inline-block cartIconHeader"
							toggle-class="header-item noti-icon"
							right
							menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
							>
							<template v-slot:button-content> -->
								<!-- <div class="cartIconHeader"> -->

								<!-- <img src="../templateAssests/img/ckgallery/Cart.png" width="30" height="30" alt=""> -->


								<!-- <div v-if="cartDetailsHead != undefined && cartDetailsHead[0].length !== 0">
									<span class="badge bg-danger rounded-pill">{{cartItemsDetailsHead.length}}</span>
								</div>
								<div v-else>
									<span></span>
								</div> -->

								<!-- <span class="badge bg-danger rounded-pill" :style="cartItemsDetailsHead.length > 0 ? 'display:block;' : 'display:none;' ">{{cartItemsDetailsHead.length}}</span> -->


								<!-- <span class="badge bg-danger rounded-pill">{{listCartData}}</span> -->
								<!-- <span class="badge bg-danger rounded-pill" v-else>0</span> -->
								<!-- </div> -->
								
							<!-- </template>
						<div v-if="cartItemsDetailsHead.length > 0">
							<div class="row align-items-center">
								<div class="col">
									<div class="head">
										<div class="row">
											<div class="col-md-3 col-lg-3 col-xl-3"> -->

												<!-- <img src="../templateAssests/img/curry-house.jpg" width="55" height="55" data-src="img/menu-thumb-1.jpg" alt="thumb" class="lazy"> -->
												
												<!-- <img v-if="cartDetailsHead != undefined && cartDetailsHead[0].length !== 0" :src="cartDetailsHead[0].restImage == null || cartDetailsHead[0].restImage == ''? 'img' : cartDetailsHead[0].restImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" alt="thumb" class="lazy">
											</div> -->
											<!-- / col -->
											<!-- <div class="col-md-9 col-lg-9 col-xl-9">
												<div  class="restaurantsDetails">
													<h5 v-if="cartDetailsHead != undefined && cartDetailsHead[0].length !== 0">{{cartDetailsHead[0].restName}}</h5>
												</div>
												<div  class="locationDetails">
													<p v-if="cartDetailsHead != undefined && cartDetailsHead[0].length !== 0">{{cartDetailsHead[0].restaurantBranchName}}</p>
												</div>
											</div> -->
											<!-- /col -->
										<!-- </div>
									</div>
								</div>
							</div>
							<simplebar  data-simplebar>
							<div class="text-reset notification-item">
							<div class="cartSection">
							<div class="main mainCart">
							<div class="cartItems">
								<h4>Cart Items <small>({{cartDetailsHead[0].cardcount}})</small></h4>
							</div>
							<div class="cartTotalItemDetails headerTotalCartItems">
							<div class="row" v-for="ItemsInCart in cartItemsDetailsHead" v-bind:key="ItemsInCart.cartID">
							<div class="col-md-10 col-lg-10 col-xl-10 dishName">
							<h5><span class="itemQty">{{ItemsInCart.quantity}}x </span>{{ItemsInCart.cuisineName}} <div class="foodTypeContent align-center" :style="{background : foodType.background}" v-for="foodType in ItemsInCart.type" v-bind:key="foodType.name"></div></h5>

							</div>
							<div class="col-md-2 col-lg-2 col-xl-2 dishDetails align-left">
								<span class="dishRate">₹{{ItemsInCart.itemTotal}}</span>
							</div>
							</div>
							</div>
							<hr class="hrBorder" style="margin: 5px 0;">
							<div class="row">
							<div class="col-md-10 col-lg-10 col-xl-10 TotalPopup">
							<h5>Total </h5>

							</div>
							<div class="col-md-2 col-lg-2 col-xl-2 align-left">
								<span class="TotaldishRatePopup">₹{{Math.round(cartDetailsHead[0].totalitemprice)}}</span>
							</div>
							</div>
							<div class="btn_1_mobile"> -->

								<!-- <a v-if="cartDetailsHead != undefined && cartDetailsHead[0].length !== 0" @click="getCheckOutAccess" class="btn_1 full-width mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Checkout</a> -->
								
								<!-- <a v-if="cartDetailsHead != undefined && cartDetailsHead[0].length !== 0" href="/checkOut" class="btn_1 full-width mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Checkout</a> -->
							<!-- </div>
							</div>
							</div>
							</div>
							</simplebar>
						</div>
						<div v-else>
							 <div class="main mainCart">
								<div class="cartItems align-center">
									<img src="../templateAssests/img/ckgallery/emptybag.png">
									<h4>No Item in Cart</h4>
								</div>
		                    </div>
						</div> -->
<!-- <div class="p-2 border-top">
<a
class="btn btn-sm btn-link font-size-14 btn-block text-center"
href="javascript:void(0)"
>
<i class="uil-arrow-circle-right me-1"></i>
{{ $t("navbar.dropdown.notification.button") }}
</a>
</div> -->
						<!-- </b-dropdown> -->

					<!-- <ul id="top_menu" class="drop_user" v-if="localStorageUserData != ''">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="../templateAssests/img/avatar1.png" height="40" width="40" alt=""></figure><span class="userName">{{userName}}</span>
								</a>
								<div class="dropdown-menu">
									<div class="dropdown-menu-content">
										<ul>
											<li><a href="#0"><i class="icon_cog"></i>Dashboard</a></li>
											<li><a href="#0"><i class="icon_document"></i>Bookings</a></li>
											<li><a href="#0"><i class="icon_heart"></i>Wish List</a></li>
											<li><a href="#0"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
							</div> -->
							<!-- /dropdown -->
						<!-- </li>
					</ul> -->
					<b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu"
					 v-if="isUserFetched != 0"
					>
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure>
									<!-- <span class="userName">{{userName}}</span> -->
									<p class="userName userNameTextLen one-line-text" :style="userName == '' ? 'width:2ch;': ''">{{userName}}</p>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
											<li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li v-if="canUserLogout == 0"><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
					<nav class="main-menu" v-else>
					<ul>
                    <li class="submenu">
                        <a href="#0" @click="$bvModal.hide('modal-register')" v-b-modal.modal-login class="show-submenu">Login</a>
                        <!-- <a href="/login" class="show-submenu">Login</a> -->
                    </li>
                    <li class="submenu">
                        <a class="show-submenu" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a>
                        <!-- <a href="/register" class="show-submenu"><button class="btn_1 gradient" type="submit">Register</button></a> -->
                    </li>
                </ul>
					</nav>

					</div>
					<!-- /top_menu -->
					<!-- <a href="#0" class="open_close">
						<i class="icon_menu"></i><span>Menu</span>
					</a> -->
			</div>
			<!-- / row -->
	    </div>
		<!-- / container -->

		<!-- Popup Modals -->
		<loginRegModal />
		<termsAndCondition />
		<cusinePopup />
		<placesPopup />
		<feedBackPopup :feedBackData="this.feedBackData"/>
		<!-- <searchBoxPopup /> -->
		<!-- <deliveryAddressPopup /> -->
		<!-- /  Popup Modals -->

	</header>
	<!-- /header -->
</template>
<style>
.mobListExt
{
	padding-bottom: 80px!important;
}
.mobBasketExt
{
	bottom: 8px!important;
}
.mainLogoImg{
	object-fit:cover;
}
.dropdown.user > a{
	padding: 0px 0 5px 45px !important;
}
.dropdown.user > a figure
{
	top:-12px;
}
ul#top_menu{
	margin-top: 8px!important;
}
.noti-icon .badge {
    height: 18px;
    min-width: 18px;
    position: absolute;
    /* top: 16px; */
    top: 11px;
    left: 33px;
	background: #A4827D!important;
	margin-top: -15px;
	border-radius: 9px;
}
.dropdown-toggle{
	margin-right: 10px!important;
}
.dropdown-toggle::after{
	display: none !important;
}
.notification-item .media:hover {
    background-color: transparent;
}
.notification-item .media{
	padding: 30px 1rem!important;
}

.dropdown-menu{
	    border-top: 3px solid #A4827D;
		width: 330px!important;
		max-width: 330px!important;
}
.user-menu{
	width: 144px!important;
	max-width: 144px!important;
	right: auto !important;
}
.dropdown-ul
{
	list-style: none;
	padding: 0px!important;
}
.dropdown-ul li{
	padding: 12px 15px;
	color: #000;
}
.dropdown-ul li a{
	color: #000;
}
.dropdown-ul li a i{
	margin-right: 8px;
}
.foodTypeContent{
	width :9.62px;
	height: 9.62px;
}
.cartSection{
	padding: 24px 24px;
}
.vegan{
	background: #5B7315;
}
.veg{
	background: #9FC925;
}
.halal{
	background: #98060F;
}
.foodTypeContent{
	border-radius: 12px;
	width: 12px;
	height: 12px;
	color:#fff;
	display: inline-block;
	font-size: 10px;
	/* margin-right: 2px; */
	margin: auto 3px;
}
.cartItemNameDiv
{
	display: flex;
    flex-wrap: wrap;
    min-height: 100%;
    align-content: center;
}
.TotalPopup h5{
	font-family: 'GothamMedium'!important;
	margin: 0px 0px 13px;
}
.TotaldishRatePopup{
	font-family: 'GothamMedium'!important;
	font-size:16px;
}
.dishRate{
	font-size: 13px;
}
.dishName h5{
	font-size: 14px;
	margin-bottom: 7px;
}
.itemQty
{
	color: #777;
	font-size: 12px;
}
ul.dropdown-ul li a{
	cursor: pointer;
}
.head
{
	padding :8px 20px !important;
}
.onlyMobile
	{
		display: none!important;;
	}
	.dishPrice
	{
		display: block!important;
	}
	.dishPrice_mobile
	{
		display: none!important;
	}
	.desc
	{
		display: block!important;
	}
	.desc_mobile
	{
		display: none!important;
	}
	.itemPriceDiv
	{
		display: block!important;
	}
	.itemPriceDiv_mobile
	{
		display: none!important;
	}
@media (max-width:971px)
{
	.onlyMobile
	{
		display: block!important;
	}
	.addressHead
	{
		display: flex;
		flex-wrap: wrap;
		min-height: 100%;
		align-content: center;
	}
	.addressHead p{
		margin-bottom: 0px;
		margin-top: 5px;
	}
	.icon_menu
	{
		font-size: 28px;
	}
	.mobile_item
	{
		padding: 12px 10px 10px 10px!important;
		margin-bottom: 1px!important;
	}
	.strip_mobile
	{
		margin-bottom: 0px!important;
	}
	a.mobile_item figure
	{
		float: left!important;
		margin-right: 10px!important;
		margin-left: 0px!important;
		border-radius: 5px!important;
	}
	.addBtnPostion_mobile
	{
		position: static!important;
		float: right!important;
	}
	.outOfStockSpan_mobile
	{
		position: static!important;
		float: right!important;
	}
	a.mobile_item h3
	{
		font-size: 15px!important;
	}
	a.mobile_item
	{
		min-height: 92px;
	}
	.itemPriceDiv
	{
		display: none!important;
	}
	.itemPriceDiv_mobile
	{
		display: inline-block!important;
	}
	.addBtn_mobile
	{
		margin-bottom: 1px!important;
	} 
	.dishPrice
	{
		display: none!important;
	}
	.dishPrice_mobile
	{
		position: static!important;
		display: inline-block!important;
	}
	.desc
	{
		display: none!important;
	}
	.desc_mobile
	{
		display: block!important;
	}
}
ul#primary_nav {
		margin-bottom: 0px;
		padding-left: 0px;
		overflow: scroll;
		white-space: nowrap;
	}
	ul#primary_nav::-webkit-scrollbar 
	{
		display: none;
	}
	.serachImgDiv
	{
		padding-right: 11px;
		/* padding-right: 22px; */
	}
	.cartIconHeader
	{
		padding-left: 11px;
	}
	.serachImg
	{
		object-fit: contain;
		padding: 3px;
	}
	.dropdown.user > a:after{
		position: absolute;
		right: 0;
	}
	.userNameTextLen
	{
		margin-bottom: 0px;
		margin-right: 8px;
		width: 12ch;
	}
	.mobileArrowCarrot
	{
		position: absolute;
		top: 10px;
		right: 0px;
	}
	.headerTotalCartItems
	{
		max-height: 90px!important;
	}
	@media only screen and (max-width: 1024px) and (min-width: 996px)  {
		.mainLogoDiv{
			text-align: right;
		}
	}
/* .simplebar-content-wrapper
{

} */
.header-item{
	height: auto!important;
	padding-top: 0px;
}
.headerAddress
{
	font-size: 14px;
	margin-bottom: 0px;
	margin-top: 10px;
}
/* .headerAddressDiv
{
	margin-bottom: 0px;
	margin-top: 10px;
} */
.userPlaceImage
{
	object-fit: contain;
	border-radius: 25px;
}
.loginReg
{
	font-size: 18px;
	color: #F3766A;
}
.head_section
{
	margin-bottom: 20px;
}
/* .navigation_ul
{
	list-style-type: none;
	padding: 0;
} */
.navigation_ul li
{
	padding: 12px 0;
	font-size: 14px;
}
.navigation_ul a li
{
	color: #777;
}
.helpHead
{
	font-size: 16px;
	color: #777;
}

.int-pm {
		/* margin-left: 40px; */
		display: flex;
		text-align: center;
		border: 1px solid #ced4da;
		width: 80px;
		height: 30px;
		margin-left: 0!important;
		background-color: #fff;
		float: right;
		margin-bottom: 4px;
		border-radius: 18px;
	}
	.int-pm .int-pm-btn {
		width: 30% !important;
		border: transparent !important;
		background-color: transparent !important;
		cursor: pointer !important;
	}
	.int-pm .int-pm-value {
		border-width: 1px 0 !important;
		border-color: #ccc !important;
		padding: 4px 0 !important;
		width: 40px !important;
		border-style: none !important;
	}
</style>
