<script>
import Vue from 'vue';

import { FormGroupPlugin } from 'bootstrap-vue'
Vue.use(FormGroupPlugin)

import { FormCheckboxPlugin } from 'bootstrap-vue'
Vue.use(FormCheckboxPlugin)

 import { IntegerPlusminus } from 'vue-integer-plusminus'

 	import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
 

export default {
    props:[
		'cusinieAddonsDetails'
	],
    data() {
      return {
        cusineModalShow:true,
        variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        footerBgVariant: 'white',
        footerTextVariant: 'dark',
		  itemTotalPopup:'',
		  itemFromModal:'',
      }
    },
    components: {
				 VsaList,
				 VsaItem,
				 VsaHeading,
				 VsaContent,
				 VsaIcon,
				 IntegerPlusminus,
				//  VueHorizontalList,
				 },
	methods: {
		selectAddons(itemPrice)
		{
			alert();
			// console.log(this.selectedAddOnItems);
			var sum = this.cusinieAddonsDetails.selectedAddOnItems.reduce((acc, item) => acc + item.price, 0);
			this.cusinieAddonsDetails.itemTotalPopup = (parseInt(itemPrice) + parseInt(sum)) * parseInt(this.cusinieAddonsDetails.cuisineDetail.quantity);
		},
	},
}
</script>

<template>
    <div class="container-fuild m-0 p-0">
        <!-- Modal item order -->
        <b-modal id="modal-cuisinePopup" scrollable  :footer-bg-variant="footerBgVariant" v-if="cusinieAddonsDetails && cusineModalShow === true" hide-header  size="md" centered>
		<div class="container" style="max-width:100%!important;padding:0px;">
		
		<div>
            <img :style="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == '' ? 'display:none;' : 'display:block;'" :src="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == ''? 'img' : cusinieAddonsDetails.cuisineDetail.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="150" style="width:150px; max-height:200px; padding: 0px 15px 10px 0;float: left;margin-right: 10px;" alt="thumb" class="lazy cuisineModalImg">
            <h5>{{cusinieAddonsDetails.cuisineDetail.name}}</h5>
            <p style="color:#F68F30;margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.restaurantName}}</p>
            <p class="menuPrice">₹{{cusinieAddonsDetails.cuisineDetail.price}} | Serves {{cusinieAddonsDetails.cuisineDetail.serves}}</p>
            <div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusinieAddonsDetails.cuisineDetail.type" v-bind:key="mealType.name">
                {{mealType.name}}
            </div>
            <p style="margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.description}}</p>
			
		</div>
		
		<br>
			<div class="row">
				<div class="customizeSection col-6 col-lg-6 col-xl-6" style="float:left;">
					<p v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="color: #a38379;margin-bottom:0px; padding-top: 8px;">Customize</p>
				</div>
				<div class="socialBtns align-right col-6 col-lg-6 col-xl-6">
					<div class="socialBtnsDiv align-center" style="background:#4C6EF5;">
						<img src="../../templateAssests/img/ckgallery/fb.png" height="15" width="10" alt="fb">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#00E676;">
						<img src="../../templateAssests/img/ckgallery/whatsapp.png" height="15" width="14" alt="whatsapp">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#A4827D;">
						<img src="../../templateAssests/img/ckgallery/share.png" height="15" width="15" alt="share">
					</div>
				</div>
			</div>
			<hr v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="opacity:1;margin: 8px 0 12px 0;">
		<!-- / customizeSection -->

		<!--  Dropdown -->
		<vsa-list class="sauceSection" v-if="cusinieAddonsDetails.cuisineAddons != undefined && cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'">
			<div  v-for="(addonItemsArr, groupIndex) in cusinieAddonsDetails.cuisineAddons" v-bind:key="addonItemsArr.addonGroupID">
    		 <vsa-item :forceActive="groupIndex == 0 ? true : false">
				<vsa-heading>
					{{addonItemsArr.groupName}} <span v-if="addonItemsArr.required == 0">(Optional)</span>
				</vsa-heading>
	
				<vsa-content>
					<div class="row" v-for="addOnItems in addonItemsArr.data" v-bind:key="addOnItems.addOnItemID">
						
						<div class="col-md-12">
					 <b-form-group v-slot="{ ariaDescribedby }">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="cusinieAddonsDetails.selectedAddOnItems"
							:aria-describedby="ariaDescribedby"
							name="addOnItems" @change="selectAddons(cusinieAddonsDetails.cuisineDetail.price)">
							<b-form-checkbox checked="true" :value="addOnItems">{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox>
						</b-form-checkbox-group>
					 </b-form-group>
						</div>
					</div>
				</vsa-content>
			</vsa-item>
			</div>
  </vsa-list>
		<!-- / Dropdown -->
		<!-- endiIF -->
	</div>
		<!-- /Container -->
		<template #modal-footer>
        <div class="w-100">
          <p class="float-left">Item total ₹{{cusinieAddonsDetails.itemTotalPopup}}</p>
		  <div class="row float-right" v-if="cusinieAddonsDetails.isInStock != 0">
			  <div class="col-6 align-right">
				 <integer-plusminus  v-model="cusinieAddonsDetails.cuisineDetail.quantity" :min="0" :max="100" :step="1">
				 </integer-plusminus>
				</div>
				<div class="col-6">
				<b-button
					size="sm"
					class="float-right viewDish-footer-btn"
				>
					Update Cart
				</b-button>
				</div>
		  </div> 
        </div>
      </template>
  </b-modal> 
    <!-- /Modal item order -->
    </div>
</template>
<style scoped>
.vsa-list 
{
    --vsa-heading-padding: 0 0;
    --vsa-highlight-color: transparent; 
    --vsa-bg-color: transparent;
    --vsa-border-color: #eaeaea;
    --vsa-default-icon-size: 0.3;
}
.vsa-item__trigger{
    padding: 10px!important;
}
</style>
<style>
.vsa-item__trigger__content
{
    font-size:14px;
    color:#777;
}
.custom-checkbox .custom-control-input{
		margin-right: 7.6px;
	}
	.custom-checkbox .custom-control-input:checked {
		background-color: #F3766A;
		border-color: #F3766A;
	}
	#modal-cuisinePopup___BV_modal_body_::-webkit-scrollbar
		{
			width: 3px;
			background-color: #F5F5F5;
		}

		#modal-cuisinePopup___BV_modal_body_::-webkit-scrollbar-thumb
		{
			border-radius: 10px;
			background-color: #e0e0e0;
			border: 2px solid #e0e0e0;
		}
		.viewDish-footer-btn{
			background-color: #ffc60c!important;
			color: #212121;
    		/* font-weight: 600; */
			border: 1px solid #ffc60c;
			height: 32px;
			width:100px;
		}
</style>