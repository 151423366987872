<script>
import vueStarRate from "vue-js-star-rating";
import { VueFeedbackReaction } from "vue-feedback-reaction";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
// import Vue from 'vue'

// https://craigh411.github.io/vue-rate-it/#/ // checkout docs for more
import { StarRating } from "vue-rate-it";
import { HeartRating } from "vue-rate-it";
import { FaRating } from "vue-rate-it";
import { ImageRating } from "vue-rate-it";

export default {
  props: ["feedBackData"],
  data() {
    return {
      //    starRatingValue:0,
      userDetailsForFeedBack: 0,
      feedback: "",
      feedbackError: false,
      //    heartRatingValue:0,
      squareRatingValue: 0,
      comments: "",
      dateOfBirth: "",
      anniversary: "",
      questAnsArr: [],
      templateID: 0,
      //    sugesstionArr: [],
      //    userSearchKey:'',
      gender: "",
      genderArr: ["Male", "Female", "Other"],
    };
  },
  components: {
    vueStarRate,
    VueFeedbackReaction,
    DatePicker,
    Multiselect,
    StarRating,
    HeartRating,
    FaRating,
    ImageRating,
  },
  methods: {
    starRating(rating, optionID, templateID) {
      var starRatingobj = {
        questID: optionID,
        answer: rating,
      };
      if(templateID)
      {
        this.templateID = templateID;
      }
      // this.starRatingValue = rating;
      this.questAnsArr.push(starRatingobj);
    },
    heartRating(rating, optionID, templateID) {
      // this.heartRatingValue = rating;
      var heartRatingobj = {
        questID: optionID,
        answer: rating,
      };
      if(templateID)
      {
        this.templateID = templateID;
      }
      // this.starRatingValue = rating;
      this.questAnsArr.push(heartRatingobj);
      // console.log(this.questAnsArr);
    },
    emojiRating(optionID, templateID) {
      var emojiRatingobj = {
        questID: optionID,
        answer: this.feedback,
      };
      if(templateID)
      {
        this.templateID = templateID;
      }
      // alert(this.feedback);
      this.questAnsArr.push(emojiRatingobj);
    },
    squareRating(rating) {
      this.squareRatingValue = rating;
    },
    onHide(event) {
      // if(event.trigger === "backdrop" || event.trigger === "headerclose")
      // {
      let updteOrderFeedbackData = JSON.stringify({
        orderID: this.feedBackData.orderDetails.oID
          ? this.feedBackData.orderDetails.oID
          : 0,
        isOrderRated: 2,
      });
      this.axios
        .post("/updteOrderFeedback", updteOrderFeedbackData)
        .then((response) => {
          // console.log(response.data.cuisineDetails);
          let status = response.status;
          if (status == 200 && response.data.status == 200) {
            // location.reload();
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
      // }
    },
    submitFeedback() {
      this.feedbackError = false;
      if (this.squareRatingValue == 0) {
        this.feedbackError = true;
        return;
      }
      this.$bvModal.hide("modal-userFeedback");
      let getFeedBackDetails = JSON.stringify({
        orderID: this.feedBackData.orderDetails.oID
          ? this.feedBackData.orderDetails.oID
          : 0,
        isOrderRated: 1,
        questionRatingArr: this.questAnsArr,
        rating: this.squareRatingValue,
        comments: this.comments,
        dateOfBirth: this.dateOfBirth,
        anniversary: this.anniversary,
        gender: this.gender,
        templateID:this.templateID,
      });
      this.axios
        .post("/addFeedbackUser", getFeedBackDetails)
        .then((response) => {
          this.questAnsArr = [];
          // console.log(response.data.cuisineDetails);
          let status = response.status;
          if (status == 200 && response.data.status == 200) {
            // location.reload();
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
};
</script>

<template>
  <div class="container-fuild m-0 p-0">
    <b-modal
      id="modal-userFeedback"
      size="md"
      hide-footer
      no-close-on-backdrop
      @hide="onHide"
    >
      <div class="feedBackMain" v-if="feedBackData != ''">
        <div class="row headerDiv">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 class="setionTitle align-left">
              We would love to hear from you
            </h2>
          </div>
          <!-- Col-6 / -->
          <div
            v-if="feedBackData.orderDetails != ''"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          >
            <div style="margin-top: 9px">
              <div class="menu_item deliverCard restaurantCard">
                <figure>
                  <img
                    :src="
                      feedBackData && feedBackData.orderDetails.restaurantImage
                    "
                    onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';"
                    height="55"
                    width="55"
                    data-src="img/menu-thumb-1.jpg"
                    alt="thumb"
                    class="lazy"
                  />
                </figure>
                <div class="deliveryCardDiv">
                  <h3 class="deliverH3">
                    {{ feedBackData.orderDetails.restaurantName }} -{{
                      feedBackData.orderDetails.restaurantBranchName
                    }}
                  </h3>
                  <span style="font-size: 14px; color: #777"
                    >Order #<span style="color: #663333">{{
                      feedBackData.orderDetails.orderID
                    }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Col-6 / -->
        </div>
        <!-- Head row End -->
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-for="(questions, i) in feedBackData.data"
            v-bind:key="questions"
          >
            <div v-if="questions.typeofQuestion == 'rating'">
              <h2 class="questTitle align-left">
                <table>
                  <tr>
                    <td style="vertical-align: top;">{{ i + 1 }}.</td>
                    <td>{{ questions.fdbackQuestion }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <vue-star-rate
                        :rateRange="0"
                        :maxIcon="5"
                        :iconHeight="30"
                        :iconWidth="30"
                        :hasCounter="false"
                        iconColor="#F9A826"
                        iconColorHover="#F9A826"
                        iconShape="star"
                        v-on:ratingSet="starRating($event, questions.optionId, questions.fdtempId)"
                      >
                      </vue-star-rate>
                      <!-- <star-rating :showRating="false" :item-size="25" :spacing="15" v-model="starRatingValue"></star-rating> -->
                    </td>
                  </tr>
                </table>
              </h2>
            </div>
            <div v-if="questions.typeofQuestion == 'smiley'">
              <h2 class="questTitle align-left">
                <table>
                  <tr>
                    <td style="vertical-align: top;">{{ i + 1 }}.</td>
                    <td>{{ questions.fdbackQuestion }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <vue-feedback-reaction
                        v-model="feedback"
                        :emojiWidth="25"
                        :containerWidth="15"
                        @input="emojiRating(questions.optionId, questions.fdtempId)"
                      />
                    </td>
                  </tr>
                </table>
              </h2>
            </div>
            <div v-if="questions.typeofQuestion == 'heart'">
              <h2 class="questTitle align-left">
                <table>
                  <tr>
                    <td style="vertical-align: top;">{{ i + 1 }}.</td>
                    <td>{{ questions.fdbackQuestion }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <vue-star-rate
                        :rateRange="0"
                        :maxIcon="5"
                        :iconHeight="30"
                        :iconWidth="30"
                        :hasCounter="false"
                        iconColor="#E0230D"
                        iconColorHover="#E0230D"
                        iconShape="heart"
                        v-on:ratingSet="heartRating($event, questions.optionId, questions.fdtempId)"
                      >
                      </vue-star-rate>

                      <!-- PROPS FOR heart-rating :max-rating="10"  :showRating="false" :itemSize="25" :borderWidth="1" :spacing="7" :activeColor="#hex" :inactiveColor="#hex" :borderColor="#hex" -->

                      <!-- <heart-rating :showRating="false" :itemSize="25" :spacing="15" v-model="heartRatingValue"></heart-rating> -->
                    </td>
                  </tr>
                </table>
              </h2>
            </div>
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h2 class="questTitle align-left">A. How was your delivery experience?</h2>
                            <vue-star-rate
                                    :rateRange="0"
                                    :maxIcon="5"
                                    :iconHeight="30"
                                    :iconWidth="30"
                                    :hasCounter="false"
                                    iconColor="#F9A826"
                                    iconColorHover="#F9A826"
                                    iconShape="star"
                                    v-on:ratingSet="starRating"
                                    >
                            </vue-star-rate>
                        </div> -->
          <!-- Star rating end -->
          <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h2 class="questTitle align-left">B. Do you enjoy the presentation, temperature and taste of the food</h2>
                            <vue-feedback-reaction v-model="feedback" />
                        </div> -->
          <!-- emoji reaction end -->
          <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h2 class="questTitle align-left">C. Rate our food</h2>
                            <vue-star-rate
                                    :rateRange="0"
                                    :maxIcon="5"
                                    :iconHeight="30"
                                    :iconWidth="30"
                                    :hasCounter="false"
                                    iconColor="#E0230D"
                                    iconColorHover="#E0230D"
                                    iconShape="heart"
                                    v-on:ratingSet="heartRating"
                                    >
                            </vue-star-rate>
                        </div> -->
          <!-- heart rating end -->
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 class="questTitle align-left">
              Would you recommend us to your friends and family
              <vue-star-rate
                :rateRange="0"
                :maxIcon="10"
                :iconHeight="30"
                :iconWidth="30"
                :hasCounter="true"
                iconColor="#F1C40F"
                iconColorHover="#F1C40F"
                iconShape="star"
                v-on:ratingSet="squareRating"
              >
              </vue-star-rate>
            </h2>
            <p v-if="feedbackError == true" class="error">
              This field is mandatory.
            </p>
          </div>
          <!-- square rating end -->
        </div>
        <!-- rating row end -->
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 class="questTitle align-left">Comments</h2>
            <textarea
              v-model="comments"
              placeholder="Your suggestion helps others to make better choices."
              class="form-control"
              rows="2"
              cols="50"
            ></textarea>
          </div>
        </div>
        <!-- Comments end -->
        <div
          class="row"
          style="margin-top: 21px"
          v-if="
            feedBackData.orderDetails != '' &&
              feedBackData.orderDetails.userDataForFeedback == 0
          "
        >
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
            style="margin-bottom: 10px"
          >
            <h2 class="questTitle align-left">Date of birth</h2>
            <date-picker
              v-model="dateOfBirth"
              format="DD-MMM-YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
            style="margin-bottom: 10px"
          >
            <h2 class="questTitle align-left">Anniversary</h2>
            <date-picker
              v-model="anniversary"
              format="DD-MMM-YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
            style="margin-bottom: 10px"
          >
            <h2 class="questTitle align-left">Gender</h2>
            <multiselect v-model="gender" :options="genderArr"></multiselect>
          </div>
        </div>
        <!-- personal Details end -->
      </div>

      <div class="row align-center submit-button">
        <a
          href="#"
          @click="submitFeedback()"
          class="btn_1 mb_5"
          style="
            color: #000 !important;
            background: #ffc60c !important;
            padding: 14px 24px;
            font-size: 16px;
            width: 100%!important;
          "
          >Submit</a
        >
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.submit-button {
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
.submit-button a {
  width: 302px !important;
}
.headerDiv {
  margin-bottom: 15px;
}
.setionTitle {
  color: #663333;
  font-size: 20px;
  margin-bottom: 0px !important;
}
.deliverCard {
  max-height: 95px;
  padding: 7px 8px;
  background: rgb(254, 241, 240);
}
.deliverCard figure {
  float: left !important;
  margin-right: 11px !important;
}
.restaurantCard figure {
  width: 50px;
  height: 50px;
  overflow: hidden;
  /* position: absolute; */
  margin: 0;
  /* left: 20px;
      top: 12px; */
  border: 1px solid #fff;
  border-radius: 3px;
}
.deliveryCardDiv {
  margin-top: 2px;
}
.deliverH3 {
  font-size: 14px;
  color: #663333;
  margin-bottom: 10px !important;
}
.questTitle {
  font-size: 13px;
  color: #663333;
  margin-bottom: 13px;
}
</style>
<style>
#modal-userFeedback___BV_modal_header_ {
  border-bottom: transparent;
}
.vue-feedback-reaction .reaction {
  /* margin: 0 auto;
    padding: 0 8px; */
  margin: 0 !important;
  padding: 0 !important;
}
.vue-feedback-reaction {
  height: 25px;
  padding-top: 5px;
}
.vue-star-rate {
  padding-top: 5px;
}
/* .vue-feedback-reaction
{
    height: 25px!important;
    margin: 0 0 20px 0;
}
.vue-reaction img
{
    height: 35px!important;
}
.vue-reaction
{
    width:40px!important;
} */
#modal-userFeedback___BV_modal_content_ {
  width: auto;
}

@media screen and (max-width: 375px) {
  .setionTitle {
    font-size: 15px;
  }

  .deliverCard {
    max-height: 85px !important;
    padding: 12px 15px !important;
  }

  .restaurantCard figure {
    width: 60px !important;
    height: 60px !important;
  }

  /* .strip {
    margin-bottom: 0px!important;
  } */

  .vue-reaction {
    position: relative;
    display: contents;
  }

  .vue-reaction img {
    height: 35px;
    margin: 0 5px;
  }

  .questTitle {
    margin-bottom: 15px;
  }
}
</style>
